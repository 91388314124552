
import Nullarbor from './Nullarbor Couch Lifetime Lawn Care Guide.pdf';
import Grange from './Sir Grange Lifetime Lawn Care Guide by Twin View Turf.pdf';
import Walter from './Sir Walter DNA Certified Lifetime Lawn Care Guide by Twin View Turf .pdf';
import TifTuf from './TifTuf Lifetime Lawn Care Guide by Twin View Turf.pdf';

export default (productCode) => {
  switch (productCode.toLowerCase()) {
    case 'nc':
      return Nullarbor;
    case 'sg':
      return Grange;
    case 'sw':
      return Walter;
    case 'tt':
      return TifTuf;
    default:
      return false;
  }
}