import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import CTAButton from '../components/CTAButton/CTAButton';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import ensureGtag from '../helpers/ensureGtag';
import SEO from '../components/SEO';
import Image from '../components/Image';
import { withQuoteWizard } from '../components/withQuoteWizard';
import { pxToRem } from '../theme';
import getProductGuide from '../assets/guides';

const API_URL = 'https://t4lmc2dtyk.execute-api.ap-southeast-2.amazonaws.com/Prod';

const styles = theme =>
  createStyles({
    topBanner: {
      backgroundColor: theme.palette.primary.main,
      padding: 30,
      textAlign: 'center',
    },
    topBannerTitle: {
      fontSize: pxToRem(45),
      lineHeight: 1,
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    topBannerSubtitle: {
      color: theme.palette.common.white,
      fontSize: pxToRem(24),
      lineHeight: 2,
      fontWeight: 300,
    },
    whiteSectionWrap: {
      padding: '60px 20px',
      [theme.breakpoints.up(350)]: {
        padding: 60,
      },
    },
    whiteSectionTitle: {
      fontSize: pxToRem(45),
      lineHeight: 1,
      paddingBottom: 25,
      maxWidth: 300,
      textAlign: 'center',
    },
    whiteSectionCopy: {
      lineHeight: 1.5,
      textAlign: 'center',
    },
    colourfulCopy: {
      fontSize: pxToRem(20),
      textAlign: 'center',
      maxWidth: 300,
      paddingBottom: 20,
    },
    imageSectionWrap: {
      padding: '90px 50px',
    },
    contactWrap: {
      minHeight: 300,
      textAlign: 'center',
    },
    contact: {
      fontSize: pxToRem(20),
      width: '100%',
      maxWidth: 450,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 600,
      lineHeight: 1.5,
      padding: '5px 0 5px',
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(28),
      },
      [theme.breakpoints.up(450)]: {
        fontSize: pxToRem(32),
      },
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      maxWidth: 300,
    },
    googleMapWrap: {
      padding: '60px 40px 0',
      [theme.breakpoints.up(350)]: {
        padding: '90px 40px 30px',
      },
      [theme.breakpoints.up(755)]: {
        padding: '90px 40px',
      },
    },
  });

const getGoogleMapUrl = (order) => {
  const encodedLocation = encodeURIComponent(order.address);
  return `https://maps.googleapis.com/maps/api/staticmap?scale=2&center=${encodedLocation}&zoom=15&size=300x280&key=AIzaSyADu0sSCvlos-9WcUwfuKK8h2SWj5n2_D0&markers=color:green%7C${encodedLocation}`;
}

const getPdfUrl = (docName, id) =>
  `${API_URL}/pdf/${docName}/${id}`;

class IndexPage extends React.Component {
  state = {
    isLoading: true,
    isLoaded: false,
    orderLoadError: false,
    dataId: (() => {
      const isSSR = typeof window === 'undefined';

       if (!isSSR) {
         try {
            if (!window.location.hash) {
              throw new Error('No hash/order id');
          }
            return window.location.hash.slice(1);
        } catch (e) {
          ensureGtag('event', 'order_details_id_missing', {
            event_category: 'portal_order_details',
            value: e.message,
          });
        }
      }

       return '';
    })(),
    orderDetails: {
      order: {
        orderId: false,
        deliveryDate: '',
        customerName: '',
        company: '',
        address: '',
        quantity: false,
        productName: '',
        productCode: '',
      },
      documentation: {
        hasWarranty: false,
        hasCertificate: false,
      },
    },
  };

  componentDidMount() {
    if (this.state.dataId) {
      fetch(`${API_URL}/customer/${this.state.dataId}`)
        .then(res => {
            if (!res.ok) {
              throw new Error(`Error loading data: ${res.status}`);
          }
            return res.json();
        })
        .then((json) => {
          ensureGtag('event', 'order_details_seen', {
            event_category: 'portal_order_details',
            value: json.order.orderId,
          });

            this.setState({
            isLoading: false,
            orderDetails: json,
            isLoaded: true,
          });
        })
        .catch((error) => {
            this.setState({
            isLoading: false,
            isLoaded: true,
            orderLoadError: true,
          });

          ensureGtag('event', 'data_load_fail', {
            event_category: 'portal_order_details',
            value: `${error.message || error} - Order hash: ${this.state.dataId}`,
          });
        });
    } else {
        this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { isLoading, dataId, orderDetails: { order, documentation }, isLoaded, orderLoadError } = this.state;
    const googleMapUrl = isLoaded ? getGoogleMapUrl(order) : '';
    const productGuide = getProductGuide(order.productCode);

    const WhiteSection = ({ children, title, ...rest }) => (
      <Grid
        item container
        alignItems="center"
        direction="column" className={classes.whiteSectionWrap} {...rest}>
        <Typography className={classes.whiteSectionTitle} component="h2">
          {title}
        </Typography>
        {children}
      </Grid>
    );

    const WhiteCopy = ({ children }) => (
      <Typography variant="body1" className={classes.whiteSectionCopy}>{children}</Typography>
    );

    const ActionSection = ({ title, copy, fileHref, children, btnText = 'View now', ...rest }) => (
      <WhiteSection title={title} {...rest}>
        <Typography variant="body1" className={classes.colourfulCopy}>{copy}</Typography>
        {fileHref && (
          <CTAButton ctaHref={fileHref} ctaText={btnText} external
            rel="noopener noreferrer"
            target="_blank"
            btnStyles={{
              backgroundColor: '#181A38',
            }}
          />
        )}
        {children}
      </WhiteSection>
    );

    const ImageSection = ({ image, bgColour }) => (
      <Grid
        item container
        alignItems="center"
        direction="column"
        className={classes.imageSectionWrap}
        style={{ backgroundColor: bgColour }}
      >
        {image()}
      </Grid>
    );

    const getImage = (relImgPath, width) => () => (
      <Image relativePath={relImgPath} style={{ width: width }} />
    );

    return (
      <React.Fragment>
        <SEO
          title="Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        >
          <meta name="robots" content="noindex,nofollow"></meta>
        </SEO>
        {isLoading && (
          <Grid container justify="center" alignItems="center" style={{ height: '70vh' }}>
            <CircularProgress color="primary" size={100} className={classes.progress} />
          </Grid>
        )}
        {!isLoading && dataId && !orderLoadError && (
          <React.Fragment>
            <Grid item container direction="column" className={classes.topBanner}>
              <Typography className={classes.topBannerTitle} component="h1">
                Lawn #{order.orderId}
              </Typography>
              <Typography className={classes.topBannerSubtitle} component="h2">
                {order.productName}
              </Typography>
            </Grid>

            <Grid item container justify="center" alignItems="center" className={classes.googleMapWrap}>
              <img src={googleMapUrl} alt="" style={{ height: 280, width: 300 }} />
              <Grid item>
                <WhiteSection title="Your Lawn" alignItems="flex-start">
                  <WhiteCopy>{order.quantity}m{<sup>2</sup>} x {order.productName}</WhiteCopy>
                  <WhiteCopy>{order.address}</WhiteCopy>
                  <WhiteCopy>Delivered on {moment(order.deliveryDate).format("Do MMM YY")}</WhiteCopy>
                </WhiteSection>
              </Grid>
            </Grid>

            {productGuide && (
              <React.Fragment>
                <ImageSection bgColour="#1BB25F" image={getImage('order-details/care-guide.png', 150)} />

                <ActionSection
                  onClick={() => {
                    ensureGtag('event', 'care_guide_link_clicked', {
                      event_category: 'portal_order_details',
                      value: order.orderId,
                    });
                  }}
                  title="Lifetime Lawn Care Guide"
                  copy="Download your lifetime lawn care guide with information specifically created for your grass variety."
                  btnText="Read now"
                  fileHref={getProductGuide(order.productCode)}
                />
              </React.Fragment>
            )}

            {documentation.hasWarranty && (
              <React.Fragment>
                <ImageSection bgColour="#4A90E2" image={getImage('order-details/waranty-cert.png', 200)} />
                <ActionSection
                  onClick={() => {
                    ensureGtag('event', 'waranty_cert_link_clicked', {
                      event_category: 'portal_order_details',
                      value: order.orderId,
                    });
                  }}
                  title="Warranty Certificate"
                  copy="View and download your lawn warranty certificate – it’s accessible 24/7 for the lifetime of your lawn."
                  fileHref={getPdfUrl('warranty', dataId)}
                />
              </React.Fragment>
            )}

            {documentation.hasCertificate && (
              <React.Fragment>
                <ImageSection bgColour="#FFD306" image={getImage('order-details/authenticity-cert.png', 200)} />
                <ActionSection
                  onClick={() => {
                    ensureGtag('event', 'authenticity_cert_link_clicked', {
                      event_category: 'portal_order_details',
                      value: order.orderId,
                    });
                  }}
                  title="Authenticity Certificate"
                  copy="View and download your Certificate of Authenticity – it’s accessible 24/7 for the lifetime of your lawn."
                  fileHref={getPdfUrl('certificate', dataId)}
                />
              </React.Fragment>
            )}

            <ImageSection bgColour="#181A38" image={getImage('order-details/question-mark.png', 100)} />
            <ActionSection
              title="Need help?"
              copy="Our team of lawn consultants are always available should you have questions or concerns:"
            >
              <Grid container direction="column" justify="space-around" alignItems="center" className={classes.contactWrap}>
                <a className={classes.contact} href="tel:1800686711" rel="noopener noreferrer">1800 686 711</a>
                <Divider className={classes.divider} />

                <Grid item container direction="column" alignItems="center">
                  <Typography className={classes.contact}>Email us</Typography>
                  
                  <Typography style={{ maxWidth: 300, paddingTop: 30 }} variant="body1">
                    Send your queries to our sales inbox and we’ll be in touch real soon:
                    <br />
                    <br />
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ color: '#181A38', textDecoration: 'none', fontWeight: 600 }} 
                      href="mailto:sales@twinviewturf.com.au">
                      sales@twinviewturf.com.au
                    </a>
                  </Typography>
                  
                </Grid>
              </Grid>
            </ActionSection>
          </React.Fragment>
        )}

        {!isLoading && !dataId && (
          <Grid container justify="center">
            <Typography className={classes.contact}>No data.</Typography>
          </Grid>
        )}

        {!isLoading && dataId && orderLoadError && (
          <Grid container direction="column" justify="space-between" alignItems="center" spacing={8}
            style={{ padding: 30, textAlign: 'center', width: '100%' }}
          >
            <Typography className={classes.contact}>Order data failed to load</Typography>
            <Typography variant="body1">Pardon for inconvenience. Give us a call on:</Typography>
            <a className={classes.contact} href="tel:1800686711" rel="noopener noreferrer">1800 686 711</a>
            <Typography variant="body1">or</Typography>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className={classes.contact}
              href="mailto:sales@twinviewturf.com.au">
              sales@twinviewturf.com.au
            </a>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(IndexPage), false,
);
